<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="baseURL + doctorModel.ProfileImagePath" size="90px" rounded />
      </template>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.uploadImgInput.click()">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*"
            @on-success="successUpload" />
          <span class="d-none d-sm-inline">{{ $t('Update') }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="(doctorModel.ProfileImagePath = null)">
          <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Name')" label-for="Name">
            <b-form-input id="Name" v-model="doctorModel.NameEN" />
          </b-form-group>
        </b-col>
        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('FamilyName')" label-for="FamilyName">
            <b-form-input id="FamilyName" v-model="doctorModel.FamilyName" />
          </b-form-group>
        </b-col>
        <!-- Field: Country -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Country')" label-for="Country">
            <v-select label="Name" v-model="doctorModel.CountryID" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countries" :reduce="val => val.ID" :clearable="false" input-id="Country"
              @input="onSelectCountry()" />
          </b-form-group>
        </b-col>
        <!-- Field: Region -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Region')" label-for="Region">
            <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-validate-on="blur" label="Name" name="Region"
              label-placeholder="Region" :placeholder="$t('Region')" v-model="doctorModel.RegionID" :options="regions"
              :icon-after="true" :reduce="(ID) => ID.ID" @input="ChangeCities()" />
          </b-form-group>
        </b-col>
        <!-- Field: City -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('City')" label-for="City">
            <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-validate-on="blur" label="Name" name="City"
              v-model="doctorModel.CityID" :options="cities" label-placeholder="City" :placeholder="$t('City')"
              :icon-after="true" :reduce="(ID) => ID.ID" />
          </b-form-group>
        </b-col>
        <!-- phonenumber -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('PhoneNumber')" label-for="PhoneNumber">
            <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode"
              @update="acceptNumber" v-model="doctorModel.Phone" />
          </b-form-group>
        </b-col>

        <!-- Field: PreferredLanguage -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('PreferredLanguage')" label-for="PreferredLanguage">
            <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-validate-on="blur" label="Name" name="language" multiple
              v-model="Langauges" :options="ListOfLanguages" :reduce="(ID) => ID.ID" label-placeholder="PreferredLanguage"
              :placeholder="$t('PreferredLanguage')" :icon-after="true" />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Gender')">
            <b-form-radio-group id="Gender-radio-group" v-model="doctorModel.GenderID" name="Gender" :options="genders" />
          </b-form-group>
        </b-col>

        <!-- Field: Speciality -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Speciality')" label-for="Speciality">
            <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-validate-on="blur" label="Name" name="Speciality" multiple
              v-model="specialities" :options="ListOfSpecialities" :reduce="(ID) => ID.ID" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePhoneNumberInput from 'vue-phone-number-input';
import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  components: {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable,
    VuePhoneNumberInput
    // point
  },
  data() {
    return {
      genders: [
        { text: this.$t('male'), value: 2 },
        { text: this.$t('female'), value: 1 }

      ],
      phonevalue: '',
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      // doctorModel:{},
      options: [],
      radios1: "luis",
      defaultPhoneData: {
        countryCode: ""
      }

    };
  },
  props: {
    doctorModel: {
      type: Object,
      required: true,
    },
  },
  methods: {

    //   acceptNumber(value) {
    // this.doctorModel.phonevalue = value.e164;
    //  //this.doctorModel.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    //  this.countries.find((x) => x.ID === this.doctorModel.CountryID).countryCode;
    //   },
    acceptNumber(value) {
      debugger;

      if (this.doctorModel.CountryID != null || this.doctorModel.CurrentUSerCountryID) {
        var country = this.countries.find((x) => (x.ID === this.doctorModel.CountryID || x.ID === this.doctorModel.CurrentUSerCountryID));
        this.defaultPhoneData.countryCode = country.CodeNumber;
        value.countryCode = this.defaultPhoneData.countryCode;
        this.phonevalue = value.e164;
        this.doctorModel.phone = this.phonevalue;
      }
      // this.phonevalue = value.e164;
      // this.defaultCountryCode.countryCode;
    },
    changeMode() {
      debugger;
      this.doctorModel.Next = true;
    },

    onSelectCountry() {
      debugger;
      var country = this.countries.find((x) => x.ID === this.doctorModel.CountryID);
      this.defaultPhoneData.countryCode = country.CodeNumber;
      //   "+" +
      //   this.countries.find((x) => x.ID === this.doctorModel.CountryID)
      //     .DailCode;
      // debugger;
      this.$store
        .dispatch(
          "RegionList/GetAllRegionsByCountryID",
          this.doctorModel.CountryID
        )
        .then((res) => {
          if (res.status == 200) {
            this.doctorModel.RegionID = null;
            this.doctorModel.CityID = null;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    ChangeCities() {
      debugger;
      this.$store
        .dispatch("CityList/GetAllCitiesByRegionID", this.doctorModel.RegionID)
        .then((res) => {
          if (res.status == 200) {
            this.doctorModel.CityID = null;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    checkDailCod() {
      debugger;
      if (
        this.doctorModel.Phone.length <=
        this.countries.find((x) => x.ID === this.doctorModel.CountryID).DailCode
          .length
      ) {
        this.doctorModel.Phone =
          "+" +
          this.countries.find((x) => x.ID === this.doctorModel.CountryID)
            .DailCode;
      }
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.doctorModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    cities() {
      return this.$store.state.CityList.cities;
    },
    regions() {
      return this.$store.state.RegionList.regions;
    },
    Langauges: {
      get() {
        return this.$store.state.DoctorList.Langauges;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_DoctorLanguages", newValue);
      },
    },
    // cities() {
    //   debugger;
    //   if (this.$store.state.CityList.cities)
    //     return this.$store.state.CityList.cities;
    //   else return [{}];
    // },
    // regions() {
    //   return this.$store.state.RegionList.regions;
    // },
    specialities: {
      get() {
        return this.$store.state.DoctorList.specialities;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_specialities", newValue);
      },
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    ListOfSpecialities() {
      debugger;
      return this.$store.state.SpecialtyList.specialties;
    },
    ListOfLanguages() {
      debugger;
      return this.$store.state.LanguageList.languages;
    },


  },
  created() {
    debugger;
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }

    this.$store.dispatch("LanguageList/GetAllLanguages");
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
  },
};
</script>
<style></style>
