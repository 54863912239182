<template>
  <div>

    <h3 class="mt-2" style="color: #1f1f1f">{{ $t("PrescriptionDetails") }}</h3>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('Title')+'*'"
            label-for="Title"
          >
            <b-form-input
              id="Title"
              v-model="doctorModel.TitleEN"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('FullName')+'*'"
            label-for="FullName"
          >
            <b-form-input
              id="FullName"
              v-model="doctorModel.FullName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('Address')"
            label-for="Address"
          >
            <b-form-input
              id="Address"
              v-model="doctorModel.Address"
            />
          </b-form-group>
        </b-col>

          <!-- Field:GraduationDate -->
          <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('GraduationDate')"
            label-for="GraduationDate"
          >
          <b-form-datepicker
        placeholder="GraduationDate"
        v-model="doctorModel.GraduationDate"
        name="GraduationDate"
      ></b-form-datepicker>
          </b-form-group>
        </b-col>

           <!-- Field: PlaceOfWork -->
           <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('PlaceOfWork')"
            label-for="PlaceOfWork"
          >
            <b-form-input
              id="PlaceOfWork"
              v-model="doctorModel.PlaceOfWork"
            />
          </b-form-group>
        </b-col>


          <!-- Field: BiographyOptional -->
          <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('BiographyOptional')"
            label-for="BiographyOptional"
          >
          <b-form-textarea
                  style="color: black"
                  :placeholder="$t('PleaseTypeHere')"
                  v-model="doctorModel.BioEN"
                >
                </b-form-textarea>
          </b-form-group>
        </b-col>

      </b-row>
   <b-row>
    <b-col  cols="12"
          md="4">
      <h3 style="color: #1f1f1f" class="mt-5">{{ $t("Licences") }}</h3>
<b-row>
 <!-- Field: Country -->
 <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            :label="$t('Country')"
            label-for="Country"
          >
            <v-select
            label="Name"
              v-model="LicenseModel.Country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countries"
              :reduce="val => val"
              :clearable="false"
              input-id="Country"
            />
          </b-form-group>
        </b-col>
        <!-- Field: LicenseNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
          :label="$t('LicenseNumber')"
            label-for="LicenseNumber"
          >
            <b-form-input
              id="Address"
              v-model="LicenseModel.Number"
            />
          </b-form-group>
        </b-col>
  

        <!-- Field: City -->
        <b-col
          cols="12"
          md="2"
        >
        <feather-icon
        :disabled="!LicenseModel.Number || !LicenseModel.Country"
          style="width: 50px; margin-left: 10px; color: black"
          icon="PlusIcon"
          @click.stop="AddLicenseNumber()"
        ></feather-icon>
        </b-col>
</b-row>
<b-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="doctorModel.DoctorLocalLicenseCountries"
          >

            <template slot="thead">
              <vs-th >{{ $t("Country") }}</vs-th>
              <vs-th >{{ $t("LicenseNumber") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.Country.Name}}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      {{ tr.Number }} 
                    </p>
                  </vs-td>
                  <vs-td>
                    <feather-icon
              style="color: black; width: 15px"
              icon="XIcon"
              @click.stop="deleteLicenseNumber(tr, indextr)"
              class="cursor-pointer"
            >
            </feather-icon>
                  </vs-td>
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-card>
  
    </b-col>

    <b-col  cols="12"
          md="4">

      <h3 style="color: #1f1f1f" class="mt-5">{{ $t("Certificates") }} </h3>

      <b-button variant="white" >
        <feather-icon
          style="color: black"
          icon="UploadIcon"
          @click.stop="ShowAddCertificate = true"
          class="cursor-pointer"
        ></feather-icon>
      </b-button>



      <b-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="doctorModel.DoctorExperiences"
          >

            <template slot="thead">
              <vs-th >{{ $t("Title") }}</vs-th>
              <vs-th >{{ $t("Attachmnet") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.TitleEN}}
                    </p>
                  </vs-td>
                  <vs-td>
                    <a
                :href="baseURL + tr.AttachPath"
                target="_blank"
                rel="nofollow"
                class="text-sm m-1"
                >download</a
              >
                  </vs-td>
                  <vs-td>
                    <feather-icon
              style="color: black; width: 15px"
              icon="XIcon"
              @click.stop="deleteDoctorExperince(tr, indextr)"
              class="cursor-pointer"
            >
            </feather-icon>
                  </vs-td>
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-card>
  
      
    </b-col>
    <b-col  cols="12"
          md="4">

            <h3 style="color: #1f1f1f" class="mt-5">{{ "C.V" }}</h3>

<b-button variant="white" >
  <feather-icon
    style="color: black"
    icon="UploadIcon"
    @click.stop="ShowAddCv = true"
    class="cursor-pointer"
  ></feather-icon>
</b-button>
<u style="color: #454a62; font-size: 15px;text-decoration: underline;"
        >{{ "C.V" }}
        <a
          :href="baseURL + doctorModel.CVPath"
          target="_blank"
          rel="nofollow"
          class="text-sm m-1"
          >download</a
        >
      </u>
     

    </b-col>
   </b-row>
     

    </b-form>

 
 <!-- popup upload Certificate -->
    <vs-popup title="" :active.sync="ShowAddCertificate">
      <b-card class="justify-center items-center" style="background-color: white">
        
        <b-form-group
          :label="$t('TitleEN')"
            label-for="TitleEN"
          >
            <b-form-input
              id="TitleEN"
              v-model="experienceModel.TitleEN"
            />
          </b-form-group>
          <b-form-group
          :label="$t('TitleAR')"
            label-for="TitleAR"
          >
            <b-form-input
              id="TitleAR"
              v-model="experienceModel.TitleAR"
            />
          </b-form-group>
          <b-form-group
          :label="$t('ReferenceAreaEN')"
            label-for="ReferenceAreaEN"
          >
            <b-form-input
              id="ReferenceAreaEN"
              v-model="experienceModel.ReferenceAreaEN"
            />
          </b-form-group>

          <b-form-group
          :label="$t('ReferenceAreaAR')"
            label-for="ReferenceAreaAR"
          >
            <b-form-input
              id="ReferenceAreaAR"
              v-model="experienceModel.ReferenceAreaAR"
            />
          </b-form-group>


          <b-form-group
          :label="$t('FromDate')"
            label-for="FromDate"
          >
          <b-form-datepicker
            placeholder="from"
            v-model="experienceModel.DateFrom"
            class="vx-col w-full"
            name="from"
            v-validate="'required'"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group
          :label="$t('To')"
            label-for="To"
          >
          <b-form-datepicker
            placeholder="to"
            v-model="experienceModel.DateTo"
            class="vx-col w-full"
            name="DateTo"
            v-validate="'required'"
          ></b-form-datepicker>
        </b-form-group>



        <b-card title="icon" class="mt-5">
          <h4>{{ $t("Image") }}</h4>
          <template v-if="experienceModel.AttachPath">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img
                :src="baseURL + experienceModel.AttachPath"
                alt="img"
                class="responsive"
              />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImgForCertificate"
              @on-success="successUpload"
              accept="image/*"
            />

            <b-button class="mr-4" variant="primary" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</b-button>
            <b-button
              type="flat"
              color="#999"
              @click="experienceModel.AttachPath = null"
              >{{ $t("RemoveImage") }}</b-button
            >
          </div>
        </b-card>

        <vs-row
          vs-w="12"
          vs-justify="flex-end"
          class="w-full mt-2"
          style="text-algin: end"
        >
          <vs-col vs-lg="3" vs-sm="6">
            <b-button
              class=""
              @click="(ShowAddCertificate = false), (experienceModel.AttachPath = null)"
              type="filled"
              >{{ $t("Back") }}</b-button
            >
          </vs-col>
          <vs-col vs-lg="3" vs-sm="6">
            <b-button
              class=""
              @click="saveExperienceModel()"
              variant="primary"
              :disabled="addCer"
              >{{ $t("Add") }}</b-button
            >
          </vs-col>
        </vs-row>
      </b-card>
    </vs-popup>

    <!-- popup upload cv -->
    <vs-popup title="" :active.sync="ShowAddCv">
        <b-card>
          <h4>{{ $t("Cv File") }}</h4>
          <template v-if="addCV">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <a
                :href="baseURL + pathCv.path"
                target="_blank"
                rel="nofollow"
                class="text-sm m-1"
              >
                <h4>{{ $t("Cv File") }}</h4></a
              >
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateCVInput"
              accept="application/pdf"
              @change="updateCurrImgForCV"
              @on-success="successUpload"
            />

            <vs-button class="mr-4" type="flat" @click="$refs.updateCVInput.click()">{{
              $t("Update CV")
            }}</vs-button>
            <vs-button type="flat" color="#999" @click="doctorModel.CVPath = null">{{
              $t("Remove CV")
            }}</vs-button>
          </div>
        </b-card>
        <vs-row
          vs-w="12"
          vs-justify="flex-end"
          class="w-full mt-2"
          style="text-algin: end"
        >
          <!-- back -->
          <vs-col vs-lg="3" vs-sm="6">
            <b-button class="" @click="ShowAddCv = false" type="filled">{{
              $t("Back")
            }}</b-button>
          </vs-col>
          <!-- add -->
          <vs-col vs-lg="3" vs-sm="6">
            <b-button class="" @click="savecvModel()" variant="primary">{{
              $t("Add")
            }}</b-button>
          </vs-col>
        </vs-row>
    </vs-popup>
 
  </div>
</template>

<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import { BAvatar,BFormDatepicker, BButton, BCard, BCardHeader,BFormTextarea ,BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  components: {
    Datepicker,
    BAvatar, BButton, BCard,BFormDatepicker, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable,BFormTextarea
    // point
  },
  data() {
    return {
      experienceModel: {},
      pathCv: {},
      addCer: true,
      ShowAddCertificate: false,
      addCV: false,
      ShowAddCv: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",

      LicenseModel: {},
      options: [],
      radios1: "luis",
    };
  },
  props: {
    doctorModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    AddLicenseNumber() {
      debugger;
      var model = {};
      model.CountryID = this.LicenseModel.Country.ID;
      model.Country = this.LicenseModel.Country;
      model.Number = this.LicenseModel.Number;
      if (this.$store.state.AppActiveUser.Doctor.ID > 0) {
        model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      } else {
        model.DoctorID = this.$route.params.ID;
      }
      this.doctorModel.DoctorLocalLicenseCountries.push(model);
      this.LicenseModel = {};
    },
    deleteLicenseNumber(data, index) {
      this.doctorModel.DoctorLocalLicenseCountries.splice(index, 1);
    },
    deleteDoctorExperince(data, index) {
      this.doctorModel.DoctorExperiences.splice(index, 1);
    },
    // ChangeRegion() {
    //   debugger;

    //   this.$store.dispatch(
    //     "RegionList/GetAllRegionsByCountryID",
    //     this.doctorModel.CountryID
    //   );
    // },

    // ChangeCities() {
    //   debugger;

    //   this.$store.dispatch(
    //     "CityList/GetAllCitiesByRegionID",
    //      this.doctorModel.RegionID
    //   );
    // },
    updateCurrImgForCertificate(input) {
      debugger;
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.experienceModel.AttachPath = response.data;
        this.addCer = false;
        debugger;
      });
    },

    updateCurrImgForCV(input) {
      debugger;
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.pathCv.path = response.data;
        this.addCV = true;
        debugger;
      });
    },
    saveExperienceModel() {
      debugger;
      if (this.experienceModel.AttachPath != null) {
        this.doctorModel.DoctorExperiences.push(this.experienceModel);

        this.clearExperienceModel();
      } else {
        this.$vs.notify({
          title: this.$t("Error"),
          text: "please upload the image",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    clearExperienceModel() {
      this.ShowAddCertificate = false;
      this.experienceModel = {};
      this.addCer = true;
    },

    savecvModel() {
      //this.ShowAddCv = false;
      debugger;
      if (this.pathCv != null) {
        this.doctorModel.CVPath = this.pathCv.path;
        //this.clearExperienceModel();
        this.ShowAddCv = false;
      } else {
        this.$vs.notify({
          title: this.$t("Error"),
          text: "please upload the image",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    changeMode() {
      this.doctorModel.Next = true;
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.doctorModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    specialities: {
      get() {
        return this.$store.state.DoctorList.specialities;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_specialities", newValue);
      },
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    ListOfSpecialities() {
      return this.$store.state.SpecialtyList.specialties;
    },
    // pathCVObj() {
    //   return this.$store.state.DoctorList.pathCV;
    // },
  },
  created() {
    this.doctorModel.CVPath;
    debugger;
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
</style>

<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}</style
>
<style>
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>
