<template>
  <component :is="doctorModel === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="doctorModel === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="doctorModel" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('MainData')}}</span>
        </template>
        <main-info :doctorModel="doctorModel" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('MoreInfo')}}</span>
        </template>
        <doctor-more-info :doctorModel="doctorModel" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
       <!-- Action Buttons -->
       <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="submitData()"
    >
      {{$t('SaveChanges')}}
    </b-button>
    <!-- <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button> -->
  </component>
</template>

<script>
import MainInfo from "@/views/doctorProfile/MInInfoo.vue";
import DoctorMoreInfo from "@/views/doctorProfile/DoctorMoreInfo.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";

import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
 import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";

export default {
  components: {
    MainInfo,
    DoctorMoreInfo,
    BAlert, BButton, BCard, BLink, BTab, BTabs
  },
  data() {
    return {
      doctorModel: {
        specialities: [],
        DoctorSpecialities: [],
        PatientMedicalHistory: {},
        Next: false,
      },
    };
  },
  methods: {
    changeMode() {
      debugger;
      this.doctorModel.Next = true;
    },
    getDoctorData() {
      debugger;
      this.$vs.loading();

      this.$store
        .dispatch("DoctorList/GetDoctor", this.$route.params.ID)
        .then((res) => {
          this.doctorModel = res.data.Data;
          this.doctorModel.specialities =
            this.doctorModel.DoctorSpecialities.map((a) => a.SpecialtyID);
          this.$store.commit(
            "DoctorList/SET_specialities",
            this.doctorModel.specialities
          );
          this.$set(this.doctorModel, "Next", false);
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    submitData() {
      this.doctorModel.DoctorSpecialities =
        this.$store.state.DoctorList.specialities.map(function (val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        });
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/UpdateDoctor", this.doctorModel)
        .then((res) => {
        
          this.$vs.loading.close();
          this.$router.push({ name: "auth-login" });
          window.showSuccess("please wait till you confirm your account, you  will notify by mail");
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  computed: {
    isFormValid() {
      debugger
      return  this.doctorModel && this.doctorModel.CountryID&& this.doctorModel.CountryID!=null
       &&this.doctorModel.NameEN && this.doctorModel.FamilyName 
       && this.doctorModel.FullName&& this.doctorModel.TitleEN ;
    },
    // ListOfSpecialities() {
    //   return this.$store.state.SpecialtyList.specialties;
    // },
  },
  created() {
    debugger
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }

    this.$store.dispatch("RegionList/GetAllRegions");
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    this.$store.dispatch("CityList/GetAllCities");
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getDoctorData();
  },
};
</script>
<style>
</style>
